<template>
  <div class="main">
    <Navigation />
    <div class="container mx-auto">
      <div
        class="row mt-4"
        v-if="Role == 'Admin' || Role == 'Country' || Role == 'Provincial'"
      >
        <div class="col-md-5">
          <div class="form-group">
            <label>Operations:</label>
            <select
              class="form-control"
              id="optionOperation"
              v-model="selectedOperation"
              v-on:change="searchChurch"
            >
              <option v-bind:key="data.id" v-for="data in Operation">
                {{ data.Operation }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div
        class="row mt-2"
        v-if="Role == 'Admin' || Role == 'Country' || Role == 'Provincial'"
      >
        <div class="col-md-5">
          <div class="form-group">
            <label class="form-label" for="church">Region:</label>
            <select
              id="optionProvince"
              class="form-control"
              v-model="selectedRegion"
              v-on:change="searchChurch"
            >
              <option v-bind:key="data.id" v-for="data in Provincial">
                {{ data.Province }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div
        class="row mt-2"
        v-if="Role == 'Admin' || Role == 'Country' || Role == 'Provincial'"
      >
        <div class="col-md-5">
          <div class="form-group">
            <label class="form-label" for="church">District:</label>
            <select
              id="optionDistrict"
              class="form-control"
              v-model="selectedDistrict"
              v-on:change="searchChurch"
            >
              <option v-bind:key="data.id" v-for="data in Districts">
                {{ data.district }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div
        class="row mt-2"
        v-if="Role == 'Admin' || Role == 'Country' || Role == 'Provincial'"
      >
        <div class="col-md-5">
          <div class="form-group">
            <label class="form-label" for="church">Churches:</label>
            <select
              id="optionChurch"
              class="form-control"
              v-model="selectedChurch"
              v-on:change="searchMembers"
            >
              <option v-bind:key="data.id" v-for="data in churchList">
                {{ data.ChurchName }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="row mt-2 d-flex flex-row-reverse">
        <div class="col-md-5 d-flex flex-row-reverse">
          <button
            class="btn btn-success"
            v-on:click="resetFields"
            data-toggle="modal"
            data-target="#modalCenter"
            data-backdrop="static"
            data-keyboard="false"
          >
            Add New Member
          </button>
        </div>
      </div>
      <hr />
      <div class="table-responsive">
        <table class="table table-md">
          <thead>
            <tr class="bg-primary text-light text-size-12 text-center">
              <th scope="col">Last Name</th>
              <th scope="col">First Name</th>
              <th scope="col">Middle Name</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="text-center"
              v-bind:key="data.id"
              v-for="data in MembersList"
            >
              <td>{{ data.lastName }}</td>
              <td>{{ data.firstName }}</td>
              <td>{{ data.middleName }}</td>
              <td class="d-flex justify-content-evenly">
                <button
                  class="btn btn-primary"
                  v-on:click="updateSearch(data.id, 1)"
                  data-toggle="modal"
                  data-target="#modalCenter"
                  data-backdrop="static"
                  data-keyboard="false"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-pencil-square"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"
                    />
                    <path
                      fill-rule="evenodd"
                      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                    />
                  </svg>
                </button>
                <button
                  class="btn btn-danger margin-left-10"
                  v-on:click="deleteMember(data.id)"
                  v-if="
                    Role == 'Admin' || Role == 'Country' || Role == 'Provincial'
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-trash"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"
                    />
                    <path
                      fill-rule="evenodd"
                      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                    />
                  </svg>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div
      class="modal fade"
      id="modalCenter"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalCenter"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header bg-success" v-if="ifView == 0">
            <h5 class="modal-title text-white" id="exampleModalLongTitle">
              New Member
            </h5>
            <button
              type="button"
              class="btn btn-default close"
              data-dismiss="modal"
              aria-label="Close"
              id = "close"
            >
              <span class="text-white" aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-header bg-primary" v-if="ifView == 1">
            <h5 class="modal-title text-white" id="exampleModalLongTitle">
              Update Member
            </h5>
            <button
              type="button"
              class="btn btn-default close"
              data-dismiss="modal"
              aria-label="Close"
              id = "close"
            >
              <span class="text-white" aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-4 mb-2">
                <div class="form-floating">
                  <input
                    v-model="FirstName"
                    type="text"
                    class="form-control"
                    id="floatingInput"
                    placeholder="First Name"
                    v-on:input="onChangeFirstName"
                  />
                  <label class="input-custom" for="floatingInput"
                    >First Name</label
                  >
                </div>
              </div>
              <div class="col-md-4 mb-2">
                <div class="form-floating">
                  <input
                    v-model="MiddleName"
                    type="text"
                    class="form-control"
                    id="floatingInput"
                    placeholder="Middle Name"
                    v-on:input="onChangeMiddleName"
                  />
                  <label class="input-custom" for="floatingInput"
                    >Middle Name</label
                  >
                </div>
              </div>
              <div class="col-md-4 mb-2">
                <div class="form-floating">
                  <input
                    v-model="LastName"
                    type="text"
                    class="form-control"
                    id="floatingInput"
                    placeholder="Last Name"
                     v-on:input="onChangeLastName"
                  />
                  <label class="input-custom" for="floatingInput"
                    >Last Name</label
                  >
                </div>
              </div>
            </div>
            <div class="row mt-4 d-flex justify-content-center">
              <div class="col-md-4 mb-2">
                <div class="form-floating">
                  <input
                    v-model="Birthday"
                    type="date"
                    class="form-control text-center"
                    id="floatingInput"
                    placeholder="Birthdate"
                    v-bind:max="dateNow"
                  />
                  <label class="input-custom" for="floatingInput"
                    >Birthdate</label
                  >
                </div>
              </div>
              <div class="col-md-4 mb-2">
                <div class="form-floating">
                  <select
                    class="form-control"
                    id="floatingInput"
                    v-model="Gender"
                  >
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                  <label class="input-custom" for="floatingInput">Gender</label>
                </div>
              </div>
              <div class="col-md-4 mb-2">
                <div class="form-floating">
                  <input
                    v-model="Email"
                    type="text"
                    class="form-control"
                    id="floatingInput"
                    placeholder="Email Address"
                  />
                  <label class="input-custom" for="floatingInput"
                    >Email Address</label
                  >
                </div>
              </div>
            </div>
            <div
              class="row mt-4 d-flex justify-content-center align-items-center"
            >
              <div class="col-md-6">
                <div class="form-check d-flex justify-content-center">
                  <label
                    >Tithers: <input type="checkbox" v-model="isTithers" />
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-success"
              v-on:click="insertMember"
              v-if="ifView == 0"
            >
              Insert
            </button>
            <button
              type="button"
              class="btn btn-primary"
              v-on:click="updateMember"
              v-if="ifView == 1"
            >
              Update
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Navigation from "../components/Navigation.vue";
export default {
  name: "Members",
  components: {
    Navigation,
  },
  created() {
    var encoded = localStorage.getItem("log");
    var actual = JSON.parse(atob(encoded));
    this.$store.commit("SET_USERLOGON", actual);
    var encoded1 = localStorage.getItem("ch");
    var actual1 = JSON.parse(atob(encoded1));
    this.$store.commit("SET_CHURCHES", actual1);
    this.selectedChurchId = this.LOGON_USER.ChurchId;
    this.createdBy = this.LOGON_USER.FirstName + " " + this.LOGON_USER.LastName;
    this.modifiedBy =
      this.LOGON_USER.FirstName + " " + this.LOGON_USER.LastName;
    this.searchChurch();
    if (this.Role != "Church") {
      this.selectedChurch = "Torino";
    }
    if (this.LOGON_USER.Role == "Church") {
      this.selectedChurchId = this.LOGON_USER.ChurchId;
    }
  },
  data() {
    return {
      MembersList: [],
      selectedChurchId: 0,
      createdBy: "",
      modifiedBy: "",
      ifView: 0,
      Operation: [
        {
          id: 0,
          Operation: "JIL Southern and Eastern Europe",
        },
      ],
      Provincial: [
        {
          id: "NW",
          Province: "North West",
        },
        {
          id: "NE",
          Province: "North East",
        },
        {
          id: "C",
          Province: "Central",
        },
        {
          id: "S",
          Province: "South",
        },
        {
          id: "I",
          Province: "Islands",    
        },
      ], 
      selectedOperation: "JIL Southern and Eastern Europe",
      selectedRegion: "North West",
      selectedDistrict: "Piemonte",
      selectedChurch: "",
      selectedMemberId: 0,
      FirstName: "",
      MiddleName: "",
      LastName: "",
      Email: "",
      Birthday: "",
      isTithers: 0,
      Gender: "Male",
    };
  },
  methods: {
    resetFields() {
      this.ifView = 0;
      this.FirstName = "";
      this.MiddleName = "";
      this.LastName = "";
      this.Email = "";
      this.Gender = "Male";
      this.Birthday = "";
      this.isTithers = false;
    },
    searchChurch() {
      this.Churches.forEach((element) => {
        if (element.Id == this.selectedChurchId) {
          this.selectedOperation = element.Operation;
          this.selectedRegion = element.Provincial;
          this.selectedDistrict = element.District;
          this.selectedChurch = element.ChurchName;
        }
      });
      var obj = {
        Operation: this.selectedOperation,
        Provincial: this.selectedRegion,
        District: this.selectedDistrict,
      };
      axios
        .post("https://api.jilcwseeo.org/api/Church/get-operation-Churches", obj)
        .then((response) => {
          let obj = response.data;
          if (obj.length > 0) {
            let glob_Churches = [];
            for (let i = 0; i <= obj.length - 1; i++) {
              let obj_data = obj[i];
              var church_obj = {
                ChurchName: obj_data.churchName,
                ChurchPastor: obj_data.churchPastor,
                Provincial: obj_data.provincial,
                Operation: obj_data.operation,
                District: obj_data.district,
                Id: obj_data.id,
                ChurchId: obj_data.churchId,
              };
              glob_Churches.push(church_obj);
            }
            var obj_church;
            this.$store.commit("SET_CHURCHES", glob_Churches);
            if (this.LOGON_USER.Role == "Church") {
              obj_church = glob_Churches.find(
                (element) => element.Id == this.LOGON_USER.ChurchId
              );
              this.selectedChurch = obj_church.ChurchName;
              this.selectedChurchId = obj_church.Id;
            }
            else
            {
              obj_church = glob_Churches.find(
                (element) => element.Id == this.selectedChurchId
              );
              this.selectedChurch = glob_Churches[0].ChurchName;
              this.selectedChurchId = glob_Churches[0].Id;
            }
            this.searchMembers();
          }
        })
        .catch((error) => {
          if (error.response) {
            this.reportData = [];
            this.$store.commit("SET_CHURCHES", []);
            this.selectedChurch = "";
          }
        });
    },
    searchMembers() {
      this.MembersList = [];
      var obj_church = this.Churches.find(
        (element) => element.ChurchName == this.selectedChurch
      );
      this.selectedChurchId = obj_church.Id;
      var obj = {
        ChurchId: obj_church.Id,
      };
      axios
        .post("https://api.jilcwseeo.org/api/Member/getMembers", obj)
        .then((response) => {
          this.MembersList = []; 
          response.data.forEach((element) => {
              this.MembersList.push(element);
          });
          this.MembersList.sort(this.dynamicSort("lastName"))
        })
        .catch((error) => {
          if (error.response) {
          }
        });
    },
    insertMember() {
      var tithers = 0;
      if (this.isTithers) tithers = 1;

      if (
        this.FirstName != "" &&
        this.MiddleName != "" &&
        this.LastName != "" &&
        this.Email != ""
      ) {
        var gender = "";
        if (this.Gender == "Male") gender = "m";
        else gender = "f";
        if (this.Birthday != this.dateNow) {
          var Fname = this.FirstName.replace(/^\s+|\s+$/gm,'');
          var Mname = this.MiddleName.replace(/^\s+|\s+$/gm,'');
          var Lname = this.LastName.replace(/^\s+|\s+$/gm,'');

          var obj = {
            FirstName: Fname,
            MiddleName: Mname,
            LastName: Lname,
            Birthdate: this.Birthday,
            Gender: gender,
            Email: this.Email,
            ChurchId: this.searchIdChurch(),
            isTithers: tithers.toString(),
          };
          axios
            .post("https://api.jilcwseeo.org/api/Member/insert-member", obj)
            .then((response) => {
              if (response.status == 200) {
                this.SWEETALERT("Success", "Record has been added.", "success");
               document.getElementById('close').click();
                this.searchMembers();
                this.resetFields();
              }
            });
        } else
          swal.fire({
            title: "Birthday not allowed",
            icon: "error",
            confirmButtonColor: "#0d6efd",
          });
      } else {
        swal.fire({
          title: "Please fill up all required textfield.",
          icon: "error",
          confirmButtonColor: "#0d6efd",
        });
      }
    },
    searchIdChurch(){
          var ret = this.Churches.find(element=> element.ChurchName == this.selectedChurch)
          return ret.Id;
    },
    updateSearch(param_id, param_view) {
      this.ifView = param_view;
      this.selectedMemberId = param_id;
      var member_obj = this.MembersList.find(
        (element) => param_id == element.id
      );
      this.selectedChurchId = member_obj.churchId;
      this.FirstName = member_obj.firstName;
      this.MiddleName = member_obj.middleName;
      this.LastName = member_obj.lastName;
      this.Email = member_obj.email;
      if (member_obj.gender == "m") this.Gender = "Male";
      else this.Gender = "Female";
      if (member_obj.isTithers.split(" ").join("") == "1")
        this.isTithers = true;
      var d = new Date(member_obj.birthDate);
      var dateReport = new Date(d.getTime() - d.getTimezoneOffset() * 60000)
        .toISOString()
        .split("T")[0];
      this.Birthday = dateReport;
    },
    updateMember() {
      var tithers = 0;
      if (this.isTithers) tithers = 1;
      if (
        this.FirstName != "" &&
        this.MiddleName != "" &&
        this.LastName != "" &&
        this.Email != ""
      ) {
        var gender = "";
        if (this.Gender == "Male") gender = "m";
        else gender = "f";
        if (this.Birthday != this.dateNow) {
          var Fname = this.FirstName.replace(/^\s+|\s+$/gm,'');
          var Mname = this.MiddleName.replace(/^\s+|\s+$/gm,'');
          var Lname = this.LastName.replace(/^\s+|\s+$/gm,'');

          var obj = {
            id: this.selectedMemberId,
            FirstName: Fname,
            MiddleName: Mname,
            LastName: Lname,
            Birthdate: this.Birthday,
            Gender: gender,
            Email: this.Email,
            ChurchId: parseInt(this.selectedChurchId),
            isTithers: tithers.toString(),
          };
          axios
            .post("https://api.jilcwseeo.org/api/Member/update-member", obj)
            .then((response) => {
              if (response.status == 200) {
                this.SWEETALERT(
                  "Success",
                  "Record has been updated.",
                  "success"
                );           
               document.getElementById('close').click();
                this.searchMembers();
                this.resetFields();
              }
            });
        } else
          swal.fire({
            title: "Birthday not allowed",
            icon: "error",
            confirmButtonColor: "#0d6efd",
          });
      } else {
        swal.fire({
          title: "Please fill up all required textfield.",
          icon: "error",
          confirmButtonColor: "#0d6efd",
        });
      }
    },
    async deleteMember(param_id) {
      var obj = {
        Id: param_id,
      };
     await axios
        .post("https://api.jilcwseeo.org/api/Member/deleteMember", obj)
        .then((response) => {
          if (response.status == 200) {
            this.SWEETALERT("Success", "Record has been deleted.", "success");
            this.searchMembers();
          }
        });
    },
    SWEETALERT(param_title, param_text, param_action) {
      const Toast = Swal.mixin({
        toast: true,
        position: "bottom-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: param_action,
        title: param_title,
        text: param_text,
      });
    },
    dynamicSort(property) {
      var sortOrder = 1;
      if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
      }
      return function (a,b) {
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
      }
    },
    onChangeFirstName(evt) {
      this.FirstName = evt.target.value.toUpperCase();
    },
    onChangeMiddleName(evt) {
      this.MiddleName = evt.target.value.toUpperCase();
    },
    onChangeLastName(evt) {
      this.LastName = evt.target.value.toUpperCase();
    }
  },
  computed: {
    Districts:function(){
      let arr = [];     
        if(this.selectedRegion == "North West"){      
          arr.push({id:"P1",district:'Piemonte'});
          arr.push({id:"VD",district:"Valle D'aosta"});
          arr.push({id:"Lig",district:'Liguria'});
          arr.push({id:"Lomba",district:'Lombardia'});    
        }
        else if(this.selectedRegion == "North East"){      
          arr.push({id:"TR",district:"Trentino"});
          arr.push({id:"VN",district:"Veneto"});
          arr.push({id:"FR",district:"Friuli"});
          arr.push({id:"ER",district:'Emilia Romagna'});    
        }
        else if(this.selectedRegion =="Central"){
           arr.push({id:"TO",district:"Toscana"});
           arr.push({id:"U",district:"Umbria"});
           arr.push({id:"MA",district:"Marche"});
           arr.push({id:"LA",district:"Lazio"});
        }
        else if(this.selectedRegion == "South")
        {
           arr.push({id:"AB",district:"Abruzzo"});
           arr.push({id:"MO",district:"Molise"});
           arr.push({id:"CA",district:"Campania"});
           arr.push({id:"PU",district:"Puglia"});
           arr.push({id:"BA",district:"Basilicata"});
           arr.push({id:"CA",district:"Calabria"});
        }
        else if(this.selectedRegion =="Islands"){
           arr.push({id:"SC",district:"Sicilia"});
           arr.push({id:"SA",district:"Sardegna"});       
        }
        this.selectedDistrict = arr[0].district;
        return arr;
    },
    Churches() {
      return this.$store.state.Churches;
    },
    churchList() {
      let arr = [];
      this.Churches.forEach((element) => {
        if (
          this.selectedOperation == element.Operation &&
          this.selectedRegion == element.Provincial &&
          this.selectedDistrict == element.District
        ) {
          this.selectedChurchId = element.id;
          arr.push(element);
        }
      });
      if(arr.length > 0)
      {
        this.selectedChurch = arr[0].ChurchName;
        this.selectedChurchId = arr[0].id;
      }
      return arr;
    },
    Role: function () {
      return this.LOGON_USER.Role;
    },
    LOGON_USER() {
      return this.$store.state.Logon;
    },
    dateNow: function () {
      var today = new Date();
      var year = today.getFullYear();
      var month = today.getMonth() + 1;
      var day = today.getDate();
      var date = year + "-" + month + "-" + day;
      return date;
    },
  }
};
</script>
<style scoped>
.input-custom {
  font-size: 16px !important;
}
input[type="checkbox"] {
  transform: scale(1);
}
table {
  width: 100%;
}
thead, tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
tbody {
  display: block;
  overflow-y: auto;
  table-layout: fixed;
  max-height: 400px;
}
.modal-body {
  flex-direction: column;
}
@media screen and (max-width: 762px) {
    thead, tbody tr {
    font-size: 12px !important;
  }
}
</style>>